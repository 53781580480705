<div id="container"
     fxLayout="column"
     fxLayoutAlign="start center">
    <p class="speiseplan-context-white">
        Online-Vorbestellungen sind ab sofort verfügbar.<br>
        Zeit auswählen, abholen & genießen!
    </p>
    <a class="speiseplan__online-order-button" href="https://bestellen.marktstubn.com/" target="_blank">
        <span>Zur Online Vorbestellung</span>
    </a>
    <p class="speiseplan-context-white"
       style="margin-top: 3rem;">Tägliche Auswahl aus 2 wechselnden Menüs & unserer Wochenkarte.
        <!--br>Reservierungen
        für Speisen nehmen wir gerne bis 11 Uhr per E-Mail an
        rettensteiner.verkauf@sbg.at entgegen.--></p>
    <div class="inner-pager"
         fxLayout="column"
         fxLayoutAlign="start center">
        <div class="speiseplan-outer-container" [fxShow]="true"
             fxLayout="row wrap"
             fxLayoutAlign="center stretch">
            <div class="speiseplan-container" *ngFor="let weekdayMenu of weekdayMenuCollection"
                 fxLayout="column"
                 fxLayoutAlign="start center">
                <div class="speiseplan-container__content" *ngIf="weekdayMenu.menu1?.hauptspeise"
                     fxLayout="column" fxLayoutAlign="start center">
                    <p class="speiseplan-container-title"
                       *ngIf="weekdayMenu.menu1?.hauptspeise">{{weekdayMenu.day}}</p>
                    <div class="speiseplan__column__menu"
                         fxLayoutAlign="center center">
                        <p class="speiseplan-container-menu"
                           *ngIf="weekdayMenu.menu1?.hauptspeise">
                            <span *ngIf="weekdayMenu.menu1?.vorspeise">{{weekdayMenu.menu1?.vorspeise}}</span>
                            <br>
                            <br>{{weekdayMenu.menu1?.hauptspeise}}
                            <br><br>{{weekdayMenu.menu1?.preis}}</p>
                    </div>
                    <div class="speiseplan__column__menu"
                         fxLayoutAlign="center center">
                        <p class="speiseplan-container-menu"
                           *ngIf="weekdayMenu.menu2?.hauptspeise">
                            <span *ngIf="weekdayMenu.menu2?.vorspeise">{{weekdayMenu.menu2?.vorspeise}}</span>
                            <br>
                            <br>{{weekdayMenu.menu2?.hauptspeise}}
                            <br><br>{{weekdayMenu.menu2?.preis}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div id="speiseplan-weekly-outer-container"
             fxLayout="column"
             fxLayoutAlign="center center">
            <p class="speiseplan-container-title" *ngIf="weeklyMenuCollection.length"
               style="margin-top: 3rem; margin-bottom: 3rem;">
                Wochenkarte</p>
            <div fxLayout="row wrap"
                 fxLayout.lt-md="column"
                 fxLayoutAlign="center stretch"
                 fxLayoutGap.lt-md="0">
                <div *ngFor="let weeklyMenu of weeklyMenuCollection">
                    <div *ngIf="!!weeklyMenu.menu.hauptspeise"
                         style="padding-left: 1rem; padding-right: 1rem;">
                        <div class="speiseplan__column__menu"
                             fxLayoutAlign="center center"
                             style="height: 10rem">
                            <p class="speiseplan-container-menu">{{weeklyMenu.menu.hauptspeise}}
                                <br>{{weeklyMenu.menu.preis}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
