<div id="container"
     fxFlex="100%"
     fxLayout="column"
     fxLayoutAlign="center center">
  <p id="title">{{entry.title}}</p>
  <p id="text">{{entry.text}}</p>
  <div id="verticalContainer"
       fxLayout="row"
       fxLayout.lt-md="column"
       fxLayoutAlign="center start">
    <img id="image#{{image}}" class="image" fxFlex="30vw" fxFlex.lt-md="60vw" (mouseout)="imageHoverOut($event)"
         (mouseover)="imageHoverIn($event)"
         *ngFor="let image of entry.images"
         src="{{image}}">
  </div>
</div>
