<section id="bdm-news-overview-container" fxLayout="column">
  <div id="bdm-news-outer-container" fxLayout="column" fxLayoutAlign="center center">
    <div class="bdm-news" fxLayout="column" fxLayoutAlign="start center"
         fxLayoutGap=".5rem"
         *ngFor="let news of newsList">
<!--      <a><img class="news-company-logo" src="{{news.userInformation.logoBase64}}"></a>-->
<!--      <p class="news-company-name"> {{news.userInformation.companyName}}</p>-->
<!--      <div class="news-seperator"></div>-->
      <h1 class="news-title">{{news.title}}</h1>
      <pre class="context news-message" style="max-width: 80vw">{{news.message}}</pre>
<!--      <div class="news-seperator"></div>-->
      <img class="news-image" *ngFor="let image of news.downloadedImages" src="data:image/jpeg;base64,{{image}}">
      <p class="news-date">News vom {{news.date}}</p>
    </div>
  </div>
</section>
