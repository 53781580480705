import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
    })
export class NavigationComponent implements OnInit {

    active = false;

    private window: Window;

    constructor(public router: Router,
                @Inject(PLATFORM_ID) private platformId) {
    }

    ngOnInit() {
    }

    onNavigationSelect() {
    }
}
