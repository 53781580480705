<div id="container"
     fxLayout="column"
     fxLayoutAlign="center center"
     fxLayoutGap=2rem
     fxLayoutGap.lt-sm="1rem">
  <h1 id="headerTitle">{{headerService.headerTitle}}</h1>
  <p class="headerContext" fxShow="false" fxShow.lt-md="true">
    Metzgerei Rettensteiner-Scharfetter
  </p>
  <p class="headerContext" fxShow.lt-md="false">
    {{headerService.headerContext}}
  </p>
  <!--fa-icon [icon]="faChevronDown" (click)="scrollToContextContainer()"></fa-icon-->
</div>
