import {Component} from '@angular/core';
import {HeaderService} from '../header.service';

@Component({
    selector: 'app-header-context',
    templateUrl: './header-context.component.html',
    styleUrls: ['./header-context.component.css']
    })
export class HeaderContextComponent {

    constructor(public headerService: HeaderService) {
    }
}
