import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-bdm-news',
    templateUrl: './bdm-news.component.html',
    styleUrls: ['./bdm-news.component.css']
    })
export class BdmNewsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
