<div id="footerContainer">
  <div id="context-container">
    <p id="contact-title">Metzgerei Rettensteiner Scharfetter</p>
    <p class="contact-context">Hans Kappacherstraße 9, 5600 St. Johann im Pongau</p>
    <p class="contact-context">Tel.: +43 (0) 6412 20 245</p>
    <p class="contact-context">Mail: rettensteiner.office@sbg.at</p>
    <p class="contact-context">Öffnungszeiten: Mo-Fr 07:30 - 18:00 Uhr, Sa 07.00 - 12.00 Uhr</p>
    <p class="contact-context"><a id="impressum-link" routerLink="/kontakt" (click)="clickedImpressum()"
                                    style="text-decoration:underline;">Impressum & Datenschutzerklärung</a></p>
  </div>
    <a href="https://www.skillagentur.com" target="_blank"><img id="host-logo-container"
                                                                src="assets/img/logos/skillagentur-logo.svg"></a>
</div>
