import {Component} from '@angular/core';
import {HeaderService} from '../../header.service';

@Component({
    selector: 'app-quality',
    templateUrl: './quality.component.html',
    styleUrls: ['./quality.component.css']
    })
export class QualityComponent {

    constructor(private headerService: HeaderService) {
        this.headerService.setHeaderTitle('Qualität');
        this.headerService.setHeaderImage('../../assets/img/quality/background.jpg');
        this.headerService.setHeaderContext('Als zertifizierter Mitgliedsbetrieb der Genussregion Pongauer' +
            ' Wild freuen wir uns Ihnen perfektes Wildbret in höchster Qualität anbieten zu können.' +
            ' Unsere Gourmets können sich auf hohe kulinarische Köstlichkeiten freuen. Aus unserer hauseigenen' +
            ' Produktion bringen wir zusätzlich eine Vielfalt an Wildwurstspezialitäten auf Ihre Teller.' +
            ' Lassen Sie sich über unser Angebot von unserem Fachpersonal beraten.');
    }
}
