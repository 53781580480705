import {Component} from '@angular/core';
import {HeaderService} from '../../header.service';

@Component({
    selector: 'app-breakfast',
    templateUrl: './breakfast.component.html',
    styleUrls: ['./breakfast.component.css']
    })
export class BreakfastComponent {

    constructor(private headerService: HeaderService) {
        this.headerService.setHeaderImage('../../assets/img/breakfast/breakfast1.jpeg');
        this.headerService.setHeaderTitle('Frühstück');
        this.headerService.setHeaderContext('Wie könnte der Tag besser starten, als mit einem ausgewogenen, gesunden Frühstück. Der Kaffee / Kakao darf dazu natürlich nicht fehlen.');
    }
}
