import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {HeaderService} from '../../header.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
    })
export class HomeComponent implements OnInit {

    faChevronDown = faChevronDown;

    internetExplorer = false;

    constructor(private headerService: HeaderService,
                @Inject(PLATFORM_ID) private platformId) {
    }

    ngOnInit() {
        setTimeout(() => {
            window.scroll({top: 0, behavior: 'smooth'});
        }, 500);
    }

    getScreenWidth() {
        if (isPlatformBrowser(this.platformId)) {
            return window.screen.availWidth;
        }
    }

    isUsingInternetExplorer() {
        if (isPlatformBrowser(this.platformId)) {
            const userAgent = window.navigator.userAgent;
            const iex = userAgent.indexOf('MSIE ');

            if (iex > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./) || (/Edge/.test(navigator.userAgent))) {
                return true;
            }
            return false;
        }
    }
}
