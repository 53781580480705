<div id="container"
     fxLayout="column"
     fxLayoutAlign="start center">
  <div class="inner-pager"
       fxLayout="column"
       fxLayoutAlign="center center"
  fxLayoutGap="2rem">
    <p class="title">Pongauer Wild</p>
    <p class="context">
      Als zertifizierter Mitgliedsbetrieb der
      Genussregion Pongauer Wild
      freuen wir uns Ihnen perfektes Wildbret
      in höchster Qualität anbieten zu können.
      Unsere Gourmets können sich auf hohe
      kulinarische Köstlichkeiten freuen.
      Aus unserer hauseigenen Produktion bringen wir zusätzlich eine Vielfalt an Wildwurstspezialitäten auf Ihren
      Teller.
      Lassen Sie sich über unser Angebot von unserem Fachpersonal beraten.</p>
    <div id="video-container">
      <video id="quality-video" width="100%" height="400px" autoplay playsinline [muted]="'muted'">
        <source src="../../../assets/video/quality/video.mp4" type="video/mp4">
      </video>
    </div>
    <p class="quality-context"><a href="http://pongauerwild.at" target="_blank" style="cursor: pointer; font-family: 'Roboto Light', sans-serif;
  font-size: 22px;
  color: #4a564a; text-decoration: none;">Hier klicken, um auf die Homepage des Pongauer Wild weitergeleitet zu
      werden.</a></p>
    <p class="title" style="padding-top: 4rem;">Salzburg Schmeckt</p>
    <p class="quality-context"><a href="http://salzburgerlandwirtschaft.at" target="_blank" style="cursor: pointer;   font-family: 'Roboto Light', sans-serif;
  font-size: 22px;
  color: #4a564a; text-decoration: none;">Hier klicken, um auf die Homepage von Salzburg Schmeckt weitergeleitet zu
      werden.</a></p>
  </div>
</div>
