import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
    providedIn: 'root'
    })
export class HeaderService {

    // tslint:disable-next-line:variable-name
    private _headerImage;
    // tslint:disable-next-line:variable-name
    private _headerTitle;
    // tslint:disable-next-line:variable-name
    private _headerContext;

    constructor(@Inject(PLATFORM_ID) private platformId) {
    }

    setHeaderImage(image) {
        this._headerImage = image;
        this.setCurrentImage(image);
    }

    setHeaderTitle(title) {
        this._headerTitle = title;
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                window.scroll({top: 0, behavior: 'smooth'});
            }, 500);
        }
    }

    setHeaderContext(context) {
        this._headerContext = context;
    }

    setCurrentImage(imageURL) {
        // tslint:disable-next-line:max-line-length
        if (isPlatformBrowser(this.platformId)) {
            document.getElementById('headerContainer').style.backgroundImage = 'linear-gradient(rgba(20, 20, 20, 0.45), rgba(20, 20, 20, 0.45)), url(' + imageURL + ')';
        }
    }

    get headerImage() {
        return this._headerImage;
    }

    get headerTitle() {
        return this._headerTitle;
    }

    get headerContext() {
        return this._headerContext;
    }
}
