import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {DynamoSEOService, DynamoHttpService, DynamoHttpInstance} from '@skillgmbh/dynamo';
import {environment} from '../environments/environment';
import {interval} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
    })
export class AppComponent {
    protected wasPageScrolled = false;

    constructor(public router: Router,
                private SEOService: DynamoSEOService,
                private httpService: DynamoHttpService) {
        this.SEOService.setAppIdentification('');

        this.registerDynamoHttpInstance('dynamo-cms', environment.dynamo_cms_proxy, false);
        this.registerDynamoHttpInstance('gastroflow-proxy', environment.gastroflow_proxy, false);

        interval(100)
            .subscribe((): void => {
                this.wasPageScrolled = window.scrollY > 0;
            });
    }

    private registerDynamoHttpInstance(key: string, url: string, authorization: boolean): void {
        this.httpService.registerInstance(key, {
            url: url,
            authorizationEnabled: authorization,
        } as DynamoHttpInstance);
    }
}
