import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
    })
export class FooterComponent implements OnInit {

    constructor(@Inject(PLATFORM_ID) private platformId) {
    }

    ngOnInit() {
    }

    clickedImpressum() {
        if (isPlatformBrowser(this.platformId)) {
            document.getElementById('inner-pager').scrollIntoView({behavior: 'smooth'});
        }
    }
}
