<div id="container"
     fxLayout="column"
     fxLayoutAlign="start center">
  <div id="inner-pager"
       fxLayout="column"
       fxLayoutAlign="center center"
       fxLayoutGap="1cm">
    <p class="catering-context">
      Ob Seminare, Firmenfeiern, Geburtstage oder Hochzeiten, wir freuen uns Ihr Ereignis ausrichten zu können.
      Wir nehmen uns Zeit für Sie und planen alles aus einer Hand, damit Sie entspannt Ihr Ereignis genießen
      können. </p>
    <p class="catering-title">Wir bieten Ihnen</p>
    <p class="catering-context2">
      Canapès<br><br>
      Kalte Platten bis zu urigen Brettlplatten<br><br>
      Warme und kalte Speisen<br><br>
      Buffets<br><br>
      Gourmet-, Grill- oder Rustikal
    </p>
    <div id="video-container" style="background-color: #0a0a0a">
      <video id="catering-video" width="100%" height="400px" autoplay>
        <source src="../../../assets/video/catering/video.mp4" type="video/mp4">
      </video>
    </div>
    <p class="catering-title">Interessiert?</p>
    <p class="catering-context2">Fragen Sie jetzt kostenlos und unverbindlich an.</p>
    <p class="catering-contact">Tel.: +43 (0) 6412 20 245<br>Mail: rettensteiner.verkauf@sbg.at</p>
  </div>
</div>
