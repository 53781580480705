import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {NavigationComponent} from './navigation/navigation.component';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {HomeComponent} from './routes/home/home.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ContextLeftComponent} from './context-left/context-left.component';
import {FooterComponent} from './footer/footer.component';
import {SpeiseplanComponent} from './routes/speiseplan/speiseplan.component';
import {CateringComponent} from './routes/catering/catering.component';
import {ContactComponent} from './routes/contact/contact.component';
import {QualityComponent} from './routes/quality/quality.component';
import {HeaderContextComponent} from './header-context/header-context.component';
import {HttpClientModule} from '@angular/common/http';
import {BreakfastComponent} from './routes/breakfast/breakfast.component';
import {BdmNewsComponent} from './routes/home/bdm-news/bdm-news.component';
import {BdmNewsOverviewComponent} from './routes/home/bdm-news/bdm-news-overview/bdm-news-overview.component';

import {
    IGoogleAnalyticsCommand,
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule
} from 'ngx-google-analytics';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatGridListModule} from '@angular/material/grid-list';

@NgModule({
    declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    HomeComponent,
    ContextLeftComponent,
    FooterComponent,
    SpeiseplanComponent,
    CateringComponent,
    ContactComponent,
    QualityComponent,
    HeaderContextComponent,
    BreakfastComponent,
    BdmNewsComponent,
    BdmNewsOverviewComponent
    ],
    imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    NgxGoogleAnalyticsModule.forRoot('G-95J08X3GR6', [
        {
        command: 'config',
        values: ['G-95J08X3GR6', { storage: 'none', client_id: null }],
        } as IGoogleAnalyticsCommand,
        ]),
    AppRoutingModule,
    FlexModule,
    ExtendedModule,
    FontAwesomeModule,
    NgxGoogleAnalyticsRouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatGridListModule,
    NgxGoogleAnalyticsModule
    ],
    bootstrap: [AppComponent],
    })
export class AppModule {
}
