import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'app-context-left',
    templateUrl: './context-left.component.html',
    styleUrls: ['./context-left.component.css']
    })
export class ContextLeftComponent implements OnInit {
    @Input() entry;

    constructor(@Inject(PLATFORM_ID) private platformId) {
    }

    ngOnInit() {
    }

    imageHoverIn(event) {
        if (isPlatformBrowser(this.platformId)) {
            if (document.body.clientWidth >= 960) {
                if (document.getElementById('verticalContainer').lastElementChild === event.target) {
                    // console.log('Element is last child of its container.');
                    return;
                }
                // console.log('hover in');
                event.target.style.marginRight = '15vw';
            }
        }
    }

    imageHoverOut(event) {
        if (isPlatformBrowser(this.platformId)) {
            if (document.body.clientWidth >= 960) {
                if (document.getElementById('verticalContainer').lastElementChild === event.target) {
                    // console.log('Element is last child of its container.');
                    return;
                }
                // console.log('hover out');
                event.target.style.marginRight = 0;
            }
        }
    }
}
