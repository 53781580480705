import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FullNews} from './model/FullNews';
import {NewsService} from './service/news.service';
import {environment} from '../../../../../environments/environment';
import {CustomFile} from './model/CustomFile';
import {isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'app-bdm-news-overview',
    templateUrl: './bdm-news-overview.component.html',
    styleUrls: ['./bdm-news-overview.component.css']
    })
export class BdmNewsOverviewComponent implements OnInit {

    public newsList: FullNews[];
    public newsToShow: FullNews[];
    public gridNewsList: FullNews[];
    private newsIndexSlicer = 1;
    public newsIndex = 0;

    constructor(private newsService: NewsService,
                private http: HttpClient,
                @Inject(PLATFORM_ID) private platformId) {
    }

    ngOnInit(): void {
        this.getNews();
    }

    getNews() {
        if (isPlatformBrowser(this.platformId)) {
            this.http.post(environment._proxy_bdm_news + '/news/get/all/user', {username: 'rettensteiner-scharfetter'})
                .toPromise()
                .then((receivedNews: FullNews[]) => {
                    for (let news of receivedNews) {
                        news = this.insertImagesIntoNews(news);
                    }
                    this.newsList = receivedNews;
                    // @ts-ignore
                    // tslint:disable-next-line:radix
                    this.pages = new Array(parseInt((this.newsList.length % this.newsIndexSlicer === 0 ? 0 : 1)
                        + this.newsList.length / this.newsIndexSlicer));
                    this.newsList = this.newsList.sort((a: FullNews, b: FullNews) => {
                        if (this.parseDate(b.date, 'dd-mm-yyyy') > this.parseDate(a.date, 'dd-mm-yyyy')) {
                            return 1;
                        } else if (this.parseDate(b.date, 'dd-mm-yyyy') < this.parseDate(a.date, 'dd-mm-yyyy')) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                });
        }
    }

    parseDate(input, format) {
        format = format || 'yyyy-mm-dd'; // default format
        let parts = input.match(/(\d+)/g),
            i = 0, fmt = {};
        // extract date-part indexes from the format
        format.replace(/(yyyy|dd|mm)/g, function(part) {
            fmt[part] = i++;
        });

        return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
    }

    insertImagesIntoNews(news: FullNews): any {
        news.downloadedImages = [];
        news.images.forEach((customFile: CustomFile) => {
            const httpOptions = {
                responseType: 'arraybuffer' as 'json'
                // 'responseType'  : 'blob' as 'json'        //This also worked
            };
            this.http.post(environment._proxy_bdm_news + '/image/get',
                {
                    id: customFile.id
                }, httpOptions)
                .toPromise()
                .then((image: string) => {
                    news.downloadedImages.push(this.arrayBufferToBase64(image));
                    return news;
                });
        });
    }

    arrayBufferToBase64(buffer) {
        if (isPlatformBrowser(this.platformId)) {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        }
    }
}
