<div class="header__view">
  <img src="assets/img/logos/rettensteiner-logo-marked.png" id="logo" alt=""
       routerLink="/home">
  <app-navigation></app-navigation>
  <div id="social-media-link" fxLayout="row" fxLayoutGap="1.2rem"
       fxShow="false" fxShow.gt-md="true">
    <a href="https://www.facebook.com/profile.php?id=100063801932792" target="_blank">
      <img class="social-icon" src="assets/icon/facebook.svg">
    </a>
    <a href="https://www.instagram.com/marktstubn/" target="_blank">
      <img class="social-icon" src="assets/icon/instagram.svg">
    </a>
  </div>
</div>
