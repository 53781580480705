import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FullNews} from '../model/FullNews';
import {environment} from '../../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
    })
export class NewsService {

    private _allNews: FullNews[];

    constructor(private http: HttpClient) {
    }

    register(user: string, pswd: string) {
        return this.http.post(environment._proxy_bdm_news + '/user/register',
            {
                username: user, password: pswd
            });
    }

    login(user: string, pswd: string) {
        return this.http.post(environment._proxy_bdm_news + '/user/login',
            {
                username: user, password: pswd
            });
    }

    createNews(author: string, message: string, date: string, title: string) {
        const d = new Date(date);
        return this.http.post(environment._proxy_bdm_news + '/news/save', {
            author,
            message,
            d,
            title
        });
    }

    appendImage(data: FormData) {
        return this.http.post(environment._proxy_bdm_news + '/image/append', data);
    }

    getNewsByAuthor(author: string) {
        return this.http.post(environment._proxy_bdm_news + '/news/get/all/user', {username: author});
    }

    updateImage(data: FormData) {
        return this.http.post(environment._proxy_bdm_news + '/image/update', data);
    }

    // updateNews(id: number, author: string, message: string, date: string, images: CustomFile[], title: string) {
    //     return this.http.post(environment._proxy + '/update-news', new FullNews(author, message, date, images, title));
    // }

    getAllNews() {
        return this.http.post(environment._proxy_bdm_news + '/news/get/all', {});
    }

    getNewsByDate(tempDate: string) {
        return this.http.post(environment._proxy_bdm_news + '/news/get/date', new Date(tempDate));
    }

    getImage(tempId: number) {
        const httpOptions = {
            responseType: 'arraybuffer' as 'json'
            // 'responseType'  : 'blob' as 'json'        //This also worked
        };

        return this.http.post(environment._proxy_bdm_news + '/image/get',
            {
                id: tempId
            }, httpOptions);
    }

    get allNews(): FullNews[] {
        return this._allNews;
    }

    set allNews(value: FullNews[]) {
        this._allNews = value;
    }
}
