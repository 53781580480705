import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './routes/home/home.component';
import {SpeiseplanComponent} from './routes/speiseplan/speiseplan.component';
import {CateringComponent} from './routes/catering/catering.component';
import {ContactComponent} from './routes/contact/contact.component';
import {BreakfastComponent} from './routes/breakfast/breakfast.component';
import {QualityComponent} from './routes/quality/quality.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent,
        data: {title: 'Die Metzgerei in Sankt Johann im Pongau', routeName: 'Home'}
    },
    {
        path: 'speiseplan',
        component: SpeiseplanComponent,
        data: {title: 'Speiseplan', routeName: 'Speiseplan'}
    },
    {
        path: 'mittagstisch',
        component: SpeiseplanComponent,
        data: {title: 'Mittagstisch', routeName: 'Mittagstisch'}
    },
    {
        path: 'partyservice',
        component: CateringComponent,
        data: {title: 'Partyservice', routeName: 'Partyservice'}
    },
    {
        path: 'catering',
        component: CateringComponent,
        data: {title: 'Catering', routeName: 'Catering'}
    },
    {
        path: 'kontakt',
        component: ContactComponent,
        data: {title: 'Kontakt', routeName: 'Kontakt'}
    },
    {
        path: 'contact',
        component: ContactComponent,
        data: {title: 'Kontakt', routeName: 'Kontakt'}
    },
    {
        path: 'frühstück',
        component: BreakfastComponent,
        data: {title: 'Frühstück', routeName: 'Frühstück'}
    },
    {
        path: 'qualität',
        component: QualityComponent,
        data: {title: 'Qualität', routeName: 'Qualität'}
    },
    {
        path: 'quality',
        component: QualityComponent,
        data: {title: 'Qualität', routeName: 'Qualität'}
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabledBlocking',
        scrollPositionRestoration: 'top'
        })],
    exports: [RouterModule]
    })
export class AppRoutingModule { }
