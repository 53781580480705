import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {HeaderService} from '../../header.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'app-catering',
    templateUrl: './catering.component.html',
    styleUrls: ['./catering.component.css']
    })
export class CateringComponent implements OnInit {

    private faChevronDown = faChevronDown;

    constructor(private headerService: HeaderService,
                @Inject(PLATFORM_ID) private platformId) {
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.headerService.setHeaderImage('../../assets/img/catering/background.png');
            document.getElementById('headerContainer').style.backgroundPosition = 'center center';
            this.headerService.setHeaderTitle('Catering');
            // tslint:disable-next-line:max-line-length
            this.headerService.setHeaderContext('Als Spezialmetzgerei mit besonderen Auszeichnungen sind wir erste Wahl, in Bereich Partyservice und Catering in\n' +
                '      St. Johann im Pongau, wenn es um die Verköstigung Ihrer Gäste geht.\n' +
                '      Wir verwenden ausschließlich unsere hauseigenen und prämierten Produkte um perfekten Geschmack zu erzielen.');
            const element = document.getElementById('catering-video');
            // @ts-ignore
            element.muted = 'muted';
        }
    }

    scrollToContextContainer() {
        if (isPlatformBrowser(this.platformId)) {
            document.getElementById('inner-pager').scrollIntoView({behavior: 'smooth'});
        }
    }

}
