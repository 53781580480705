import {Component} from '@angular/core';
import {HeaderService} from '../../header.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
    })
export class ContactComponent {

    constructor(private headerService: HeaderService) {
        this.headerService.setHeaderImage('../../assets/img/contact/background.jpg');
        this.headerService.setHeaderTitle('Kontakt');
        this.headerService.setHeaderContext('Die Metzgerei Rettensteiner Scharfetter in Sankt Johann im Pongau hat' +
            ' sich auf Kunden eingelassen, welche sich\n' +
            ' bewusst und mit Freude ernähren.\n' +
            ' Für Sie ist Fleisch kein billiger Sattmacher, sondern ein wertvoller Bestandteil einer ausgewogenen Ernährung.\n' +
            ' Diejenigen, die mit Genuss natürliches Fleisch verzehren möchten und auf Herkunft und Qualität denselben Wert\n' +
            ' legen, sind bei uns genau richtig.');
    }
}
