<div class="navigation__mobile__container"
     [fxShow]="false"
     [fxShow.lt-md]="true">
    <button class="navigation__mobile__button"
            mat-button
            [matMenuTriggerFor]="aboutMenu"
            fxLayoutAlign="center center">
        <mat-icon style="font-size: 1.3rem; margin-top: .1rem; margin-right: .1rem;">menu</mat-icon>
        Menü
    </button>
    <mat-menu #aboutMenu="matMenu"
              yPosition="below">
        <button mat-menu-item
                routerLink="home">
            <span>Startseite</span>
        </button>
        <button mat-menu-item
                routerLink="speiseplan">
            <span>Speiseplan</span>
        </button>
        <button mat-menu-item
                routerLink="frühstück">
            <span>Frühstück</span>
        </button>
        <button mat-menu-item>
            <a href="https://www.metzgerei-rettensteiner-scharfetter.at/speisekarte.pdf" target="_blank">Speisekarte</a>
        </button>
        <button mat-menu-item
                routerLink="qualität">
            <span>Qualität</span>
        </button>
        <button mat-menu-item
                routerLink="kontakt">
            <span>Kontakt</span>
        </button>
    </mat-menu>
</div>
<nav fxLayoutAlign="start start" fxShow.lt-md="false">
    <ul fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="1.5rem">
        <li fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap=".3rem"
            routerLink="/home"
            [class.active]="router.isActive('home', true)">
            <a>Start</a>
        </li>
        <li fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap=".3rem"
            routerLink="/speiseplan"
            [class.active]="router.isActive('speiseplan', true)">
            <a>Speiseplan</a>
        </li>
        <li fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap=".3rem"
            routerLink="/frühstück"
            [class.active]="router.isActive('frühstück', true)">
            <a>Frühstück</a>
        </li>
        <li fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap=".3rem">
            <a href="https://www.metzgerei-rettensteiner-scharfetter.at/speisekarte.pdf" target="_blank">Speisekarte</a>
        </li>
        <li fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap=".3rem"
            routerLink="/qualität"
            [class.active]="router.isActive('qualität', true)">
            <a>Qualität</a>
        </li>
        <li fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap=".3rem"
            routerLink="/kontakt"
            [class.active]="router.isActive('kontakt', true)">
            <a>Kontakt</a>
        </li>
    </ul>
</nav>
